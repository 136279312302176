import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import classNames from "classnames"
import { liveMeetingCablePrefix, LiveMeetingChannelName, CaseStage } from "../../features/liveMeeting/state/liveMeetingTypes"
import LiveMeetingHeaderContainer from "../../features/liveMeeting/components/header/LiveMeetingHeaderContainer"
import { fetchMeetingAction } from "../../features/meeting/state/meetingActions"
import { fetchLiveMeetingConfigAsync } from "../../features/liveMeeting/state/liveMeetingActions"
import LiveMeetingUserContainer from "../../features/liveMeeting/components/user/LiveMeetingUserContainer"
import LiveMeetingViewControlsContainer from "../../features/liveMeeting/components/viewControls/LiveMeetingViewControlsContainer"
import LiveMeetingCaseContainer from "../../features/liveMeeting/components/case/LiveMeetingCaseContainer"
import WebRTCSession from "../../features/webRTC/components/WebRTCSession"
import { screenshareStreamPublisherOptions, userStreamPublisherOptions } from "../../features/webRTC/state/webRTCStreamProperties"
import WebRTCPublisher from "../../features/webRTC/components/WebRTCPublisher"
import { IHaveMeetingId } from "../../features/meeting/state/meetingTypes"
import LiveMeetingSharedView from "../../features/liveMeeting/components/sharedView/LiveMeetingSharedView"
import LiveMeetingYourView from "../../features/liveMeeting/components/yourView/LiveMeetingYourView"
import WebRTCScreenPublisher from "../../features/webRTC/components/WebRTCScreenPublisher"
import CableConnection from "../../shared/components/cable/CableConnection"
import { getMeetingById } from "../../shared/selectors/meeting"
import { fetchCaseAssetsAction } from "../../features/assets/state/assetsActions"
import LoadingSpinner from "../../shared/components/ui/LoadingSpinner"
import { useTranslation } from "react-i18next"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { fetchMeetingCaseAction } from "../../features/meetingCase/state/meetingCaseActions"
import ChatViewOpenButton from "../../features/liveMeeting/components/chat/ChatViewOpenButton"
import ChatView from "../../features/liveMeeting/components/chat/ChatView"
import { TenantConfigContext } from "../../features/tenantConfig/contexts/TenantConfigContext"
import { useBeforeunload } from "react-beforeunload"
import { getSelectedMeetingCase } from "../../shared/selectors/liveMeetingConfig/meetingCase"
import { displayUserMessageAction } from "../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../features/userMessage/state/userMessageTypes"
import { getUserById } from "../../shared/selectors/user"
import { useAppSelector } from "../../shared/hooks"

const LiveMeetingPage: React.FC = (): JSX.Element => {
  // Preload all the required translaions to prevent page reload when suspense triggers
  useTranslation(["shared", "liveMeeting", "case", "meeting", "user", "asset"])
  const { meetingId } = useParams<IHaveMeetingId>()
  const dispatch = useDispatch()
  const meeting = useAppSelector((state) => getMeetingById(state, { meetingId }))
  const webRtcApiKey = meeting?.webRtcApiKey
  const sessionId = meeting?.sessionId
  const meetingToken = meeting?.token
  const liveMeetingUI = useAppSelector((state) => state.ui.liveMeeting)
  const selectedMeetingCase = useAppSelector(getSelectedMeetingCase)
  const caseStage = useAppSelector((state) => state.liveMeetingConfig.caseStage)
  const recommendationsSavedAt = useAppSelector((state) => state.liveMeetingConfig.recommendationsSavedAt)
  const presenterId = useAppSelector((state) => state.liveMeetingConfig.presenterId)
  const scribeId = useAppSelector((state) => state.liveMeetingConfig.scribeId)
  const presenter = useAppSelector((state) => getUserById(state, presenterId))
  const scribe = useAppSelector((state) => getUserById(state, scribeId))

  const [isChatOpen, setIsChatOpen] = useState(false)

  const { messagingEnabled } = useContext(TenantConfigContext)

  const [connectionRefresherIndex, setConnectionRefresherIndex] = useState(0)

  useBeforeunload(() => {
    setConnectionRefresherIndex(connectionRefresherIndex + 1)
  })

  const refetchMeetingCase = () => {
    // Refresh case assets
    dispatch(fetchCaseAssetsAction(selectedMeetingCase?.case))
    // Refresh meeting case assets & meeting case status
    dispatch(fetchMeetingCaseAction(selectedMeetingCase?.id))
  }

  useEffect(() => {
    dispatch(fetchMeetingAction(meetingId))
    dispatch(fetchLiveMeetingConfigAsync(meetingId))
    dispatch(fetchAllUsersAsync())
  }, [])

  useEffect(() => {
    if (!selectedMeetingCase) return
    refetchMeetingCase()
  }, [selectedMeetingCase?.id])

  useEffect(() => {
    if (!selectedMeetingCase || !recommendationsSavedAt || !recommendationsSavedAt[selectedMeetingCase.id]) return
    refetchMeetingCase()
  }, [recommendationsSavedAt])

  useEffect(() => {
    if (!scribeId || !scribe) return
    dispatch(displayUserMessageAction({
      messageKey: "scribeChangedWithName",
      messageArgs: {
        firstName: scribe.firstName,
        lastName: scribe.lastName
      },
      type: UserMessageTypes.SUCCESS
    }))
  }, [scribeId])

  useEffect(() => {
    if (!presenterId || !presenter) return
    dispatch(displayUserMessageAction({
      messageKey: "presenterChangedWithName",
      messageArgs: {
        firstName: presenter.firstName,
        lastName: presenter.lastName
      },
      type: UserMessageTypes.SUCCESS
    }))
  }, [presenterId])

  const isSplitScreen = liveMeetingUI.isSplitScreen || caseStage == CaseStage.APPROVING

  const approving = caseStage == CaseStage.APPROVING

  if (!meetingToken) return <LoadingSpinner />

  const renderMessaging = () => {
    if (!messagingEnabled) { return null }
    return (
      <>
        <ChatViewOpenButton
          isOpen={isChatOpen}
          setIsOpen={setIsChatOpen}
          chatId={meeting.chatId}
        />
        <ChatView
          chatId={meeting.chatId}
          isOpen={isChatOpen}
          setIsOpen={setIsChatOpen}
        />
      </>
    )
  }

  return (
    <CableConnection
      resourceId={meetingId}
      channelName={LiveMeetingChannelName}
      actionPrefix={liveMeetingCablePrefix}
      key={connectionRefresherIndex}
      awaitConnection
    >
      <WebRTCSession webRtcApiKey={webRtcApiKey} sessionId={sessionId} meetingToken={meetingToken}>
        <WebRTCPublisher publisherOptions={userStreamPublisherOptions} />
        <WebRTCScreenPublisher publisherOptions={screenshareStreamPublisherOptions} />
        {renderMessaging()}
        <div className="flex flex-col h-screen bg-ch-blue-100">
          <LiveMeetingHeaderContainer meetingId={meetingId} />

          <div className="flex flex-col flex-1 overflow-hidden mx-5 mb-5">
            <div className="lg:flex">
              <div className="lg:w-1/2 bg-white rounded-md p-2 mb-2">
                <LiveMeetingCaseContainer meetingId={meetingId} />
              </div>
              <div className="lg:w-1/2 lg:ml-1 flex mb-2">
                <div className="bg-ch-blue-500 rounded-md p-2 flex-1 sm:flex-auto sm:mr-1">
                  <LiveMeetingUserContainer />
                </div>
                <div className="bg-ch-blue-500 rounded-md p-2 hidden sm:block">
                  <LiveMeetingViewControlsContainer />
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:grid sm:grid-cols-2 gap-1 overflow-hidden">
              <div
                className={classNames(
                  "flex-1 bg-white rounded-md overflow-hidden",
                  {
                    "col-span-1": isSplitScreen,
                    "col-span-2": !isSplitScreen,
                  }
                )}
              >
                <LiveMeetingSharedView meetingId={meetingId} />
              </div>
              <div
                className={classNames(
                  "flex-1 bg-white rounded-md overflow-hidden",
                  "relative",
                  {
                    "col-span-1 block": approving,
                    "col-span-1 hidden sm:block": isSplitScreen && !approving,
                    "hidden": !isSplitScreen && !approving,
                  }
                )}
              >
                <LiveMeetingYourView meetingId={meetingId} />
              </div>
            </div>
          </div>
        </div>
      </WebRTCSession>
    </CableConnection>
  )
}

export default LiveMeetingPage
