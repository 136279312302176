import React, { RefObject, useState } from "react"
import { IAppState } from "../../../../app/appTypes"
import WebRTCSubscriber from "../../../webRTC/components/WebRTCSubscriber"
import { screenshareStreamSubscriberOptions } from "../../../webRTC/state/webRTCStreamProperties"
import LiveMeetingSharedViewBar from "./LiveMeetingSharedViewBar"
import { MockStore } from "redux-mock-store"
import { getLiveMeetingPresenter } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"
import LiveMeetingSharedViewPresenter from "./LiveMeetingSharedViewPresenter"
import LiveMeetingSharedViewAssetsObserver from "./LiveMeetingSharedViewAssetsObserver"
import { useSelector } from "react-redux"

interface IProps {
  store?: MockStore
  meetingId: string
}

const LiveMeetingSharedView: React.FC<IProps> = (props: IProps): JSX.Element => {
  const presenter = useSelector((state: IAppState) => getLiveMeetingPresenter(state, props))
  const user = useSelector(getCurrentUserWithConfig)
  const isPresenter = user?.config?.isPresenter

  const [ref, setRef] = useState<RefObject<HTMLDivElement>>()
  const renderSharedView = () => {
    if (isPresenter) return <LiveMeetingSharedViewPresenter meetingId={props.meetingId} />

    if (presenter?.config?.isSharingScreen) {
      return (
        <WebRTCSubscriber
          setRef={setRef}
          userId={user.id}
          subscriberOptions={screenshareStreamSubscriberOptions}
          isScreenShareStream={true}
        />
      )
    }
    return <LiveMeetingSharedViewAssetsObserver meetingId={props.meetingId} />
  }

  return (
    <div className="flex flex-col h-full">
      <LiveMeetingSharedViewBar presenter={presenter} fullScreenRef={ref} />
      {renderSharedView()}
    </div>
  )
}

export default LiveMeetingSharedView
