import React, { useState } from "react"
import { Formik, Form } from "formik"
import Label from "../../../../shared/components/forms/Label"
import { useTranslation } from "react-i18next"
import { giveConsentAction } from "../../state/liveMeetingActions"
import { ConsentResponse } from "../../state/liveMeetingTypes"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconReject, IconNa, IconAccept } from "../../../../svg/icons"
import classNames from "classnames"
import FieldError from "../../../../shared/components/forms/FieldError"
import MentionField from "../../../../shared/components/forms/MentionField"
import { IAppState } from "../../../../app/appTypes"
import { getMeetingUsers } from "../../../../shared/selectors/meeting"

interface IProps {
  meetingId: string
}

const LiveMeetingMyConsent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch()
  const submitConsent = (response: ConsentResponse, text: string) => (() => dispatch(giveConsentAction(response, text)))
  const users = useSelector((state: IAppState) => getMeetingUsers(state, props))
  const { t } = useTranslation(["shared", "case"])
  const [consent, setConsent] = useState<ConsentResponse | undefined>(undefined)
  const buttonStyle = "flex cursor-pointer items-center hover:bg-ch-gray-300 px-4 py-1 rounded-full m-1"
  const buttonClasses = (thisResponse: boolean) => classNames(buttonStyle, {
    "bg-ch-gray-300": thisResponse,
    "bg-ch-gray-200": !thisResponse,
  })
  const approved = consent == ConsentResponse.APPROVE
  const rejected = consent == ConsentResponse.REJECT
  const na = consent == ConsentResponse.NA
  const approveColor = approved ? "text-green-500" : "text-ch-gray-400"
  const rejectColor = rejected ? "text-red-500" : "text-ch-gray-400"
  const naColor = na ? "text-ch-gray-600" : "text-ch-gray-400"
  const baseIconStyle = "fill-current w-6 g-6 lg:w-8 lg:h-8 ml-3"

  const initialValues = {
    comment: ""
  }

  return (
    <div className="flex flex-col flex-1 p-2 overflow-scroll">
      <div className="p-2 rounded-md mt-2">
        <Formik
          initialValues={initialValues}
          onSubmit={(_values, _actions) => null}
        >
          {({
            handleBlur,
            setFieldValue,
            values,
            errors,
            touched
          }) => (
            <Form className="flex-1">
              <div className="mb-2 hidden sm:block">
                <Label name="comment">
                  <span>{t("shared:yourResponse")}:</span>
                </Label>

                <MentionField
                  maxLength={1500}
                  name="comment"
                  onBlur={handleBlur}
                  users={users}
                  value={values.comment}
                  setValue={(body: string) => { setFieldValue("comment", body) }}
                  height={100}
                />

                <FieldError
                  errorMessage={errors.comment}
                  isVisible={errors.comment && touched.comment}
                />
              </div>
              <div className="flex flex-wrap flex-1 space-x-2 lg:space-x-8 justify-center mb-2">
                <div className={buttonClasses(approved)} onClick={() => setConsent(ConsentResponse.APPROVE)}>
                  {t("case:approveRecommendation")}
                  <IconAccept className={classNames(baseIconStyle, approveColor)} />
                </div>
                <div className={buttonClasses(rejected)} onClick={() => setConsent(ConsentResponse.REJECT)}>
                  {t("case:rejectRecommendation")}
                  <IconReject className={classNames(baseIconStyle, rejectColor)} />
                </div>
                <div className={buttonClasses(na)} onClick={() => setConsent(ConsentResponse.NA)} >
                  {t("shared:na")}
                  <IconNa className={classNames(baseIconStyle, naColor)} />
                </div>
              </div>
              <div className="flex flex-1 space-x-8 justify-center">
                <Button
                  variant={StyleVariants.BLUE}
                  action={submitConsent(consent, values.comment)}
                  isDisabled={consent == undefined}
                >
                  {t("submit")}
                </Button>
              </div>
            </Form>
          )}
        </Formik >
      </div>
    </div>
  )
}

export default LiveMeetingMyConsent
