import React from "react"
import LiveMeetingUserAudioSettings from "./LiveMeetingUserAudioSettings"
import LiveMeetingUserDetails from "./LiveMeetingUserDetails"
import LiveMeetingUserStreamControls from "./LiveMeetingUserStreamControls"

const LiveMeetingUserContainer: React.FC = (): JSX.Element => {
  const renderContent = (): JSX.Element => {
    return (
      <div className="flex space-between md:grid md:grid-cols-12 gap-3 relative">
        <div className="md:col-span-5">
          <LiveMeetingUserDetails />
        </div>
        <div className="flex-1 md:col-span-6">
          <LiveMeetingUserStreamControls />
        </div>
        <div className="hidden sm:block md:col-span-1 xl:static absolute right-0 flex justify-end">
          <LiveMeetingUserAudioSettings />
        </div>
      </div>
    )
  }

  return renderContent()
}

export default LiveMeetingUserContainer
